<template>
	<v-dialog v-model="show" width="450" @keydown.esc="cancel">
		<v-card>
			<v-card-title>
				Upload new image
			</v-card-title>

			<v-card-text>
				<v-row>
					<v-col>
						<VImage ref="image" scalable movable />
					</v-col>
				</v-row>

				<v-row v-if="image">
					<v-col>
						<v-text-field
							v-for="(thumbnail, i) in image.thumbnails"
							:key="i"
							:value="thumbnail.url"
							:label="i"
						/>
					</v-col>
				</v-row>

				<v-row>
					<v-col align="right">
						<v-btn
							v-if="!image"
							@click="upload"
							:loading="loading"
						>
							Upload
						</v-btn>
						<v-btn v-else @click="accept">
							Ok
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

	</v-dialog>
</template>

<script>
import VImage from "@/components/material/Image";

export default {
	components: {
		VImage,
	},
	data() {
		return {
			show: false,
			loading: false,
			image: null,
		}
	},
	name: `NewImage`,
	methods: {
		open() {
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
				this.reset()
				this.show = true
			})
		},
		close() {
			this.show = false
		},
		accept() {
			this.close()
			this.resolve()
		},
		upload() {
			this.loading = true
			this.$refs.image.uploadImage()
				.then(res => this.image = res.result)
				.finally(() => this.loading = false)
		},
		cancel() {
			this.reject()
			this.close()
		},
		reset() { Object.assign(this.$data, this.$options.data.apply(this)) },
	},
}
</script>