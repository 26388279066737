<template>
	<v-container
			fluid
	>
		<NewImage ref="newImage" />

		<v-btn elevation="0" @click="$refs.newImage.open()" class="mb-4">
			<v-icon>mdi-plus</v-icon>
		</v-btn>

		<v-row justify="center">
			<v-col cols="12">
				<v-card>
					<v-card-text>
						<v-data-table
							:loading="loading"
							:headers="headers"
							:items="items"
							:server-items-length="totalItems"
							:options.sync="options"
							item-key="id"
							class="row-pointer"
							:footer-props="{'items-per-page-options':[10,25,50]}"
						>
							<template v-slot:[`item.image`]="{ item }">
								<v-img class="ma-2" :src="item.thumbnails.icon.url" max-height="72" max-width="72" />
							</template>
							<template v-slot:[`item.created_at`]="{ item }">
								{{ dayjs(item.created_at).format(`YYYY-MM-DD HH:mm:ss`) }}
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import NewImage from "@/components/dialogs/NewImage"

	export default {
		components: {
			NewImage,
		},
		data: () => ({
			loading: false,
			headers: [
				{ text: ``, value: `image`, sortable: false },
				{ text: `MD5`, value: `md5`, sortable: false },
				{ text: `Status`, value: `status`, sortable: false },
				{ text: `Created`, value: `created_at` },
			],
			options: {
				sortDesc: [true],
				sortBy: [`created_at`],
			},
			totalItems: 0,
			items: [],
		}),
		methods: {
			refresh () {
				this.loading = true
				this.$httpInt.get(`/v2/app/images`, { params: this.$paginationParams(this) })
					.then(res => {
						this.items = res.data.result
						this.totalItems = res.data.pagination.total
					})
					.finally(() => this.loading = false)
			},
		},
		mounted () {
			this.refresh()
		},
		name: `CommunityImages`,
		watch: {
			options: {
				handler () {
					this.refresh()
				},
			},
		},
	}
</script>
